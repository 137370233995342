import React from 'react';
import {
  CalculatorOutlined,
  CommentOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  FileTextOutlined,
  HomeOutlined,
  MessageOutlined,
  PercentageOutlined,
  TeamOutlined,
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'dashboard',
    key: 'dashboard',
    url: '/dashboard',
    icon: <DashboardOutlined />,
  },
  {
    title: 'properties',
    key: 'properties',
    icon: <HomeOutlined />,
    children: [
      {
        title: 'new_property',
        key: 'new_property',
        url: '/properties/form',
      },
      {
        title: 'properties',
        key: 'list_property',
        url: '/properties/list',
      },
    ],
  },
  {
    title: 'acheter',
    key: 'acheter',
    url: '/acheter/list',
    icon: <DollarCircleOutlined />,
  },
  {
    title: 'contact',
    key: 'contact',
    url: '/contact/list',
    icon: <MessageOutlined />,
  },
  {
    title: 'calculatrice_hypothecaire',
    key: 'calculatrice_hypothecaire',
    url: '/calculatrice-hypothecaire',
    icon: <CalculatorOutlined />,
  },
  {
    title: 'demande_evaluation',
    key: 'demande_evaluation',
    url: '/demande-evaluation/list',
    icon: <PercentageOutlined />,
  },
  {
    title: 'blog',
    key: 'blog',
    icon: <FileTextOutlined />,
    children: [
      {
        title: 'new_blog',
        key: 'new_blog',
        url: '/blog/form',
      },
      {
        title: 'blog',
        key: 'list_blog',
        url: '/blog/list',
      },
    ],
  },
  {
    title: 'conseil',
    key: 'conseil',
    icon: <CommentOutlined />,
    children: [
      {
        title: 'new_conseil',
        key: 'new_conseil',
        url: '/conseil/form',
      },
      {
        title: 'conseil_acheteur',
        key: 'conseil_acheteur',
        url: '/conseil/acheteur',
      },
      {
        title: 'conseil_vendeur',
        key: 'conseil_vendeur',
        url: '/conseil/vendeur',
      },
    ],
  },
  {
    title: 'volet_social',
    key: 'volet_social',
    icon: <TeamOutlined />,
    children: [
      {
        title: 'new_association',
        key: 'new_association',
        url: '/volet-social/form',
      },
      {
        title: 'volet_social',
        key: 'list_association',
        url: '/volet-social/list',
      },
    ],
  },
];
