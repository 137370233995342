import { Flex, Modal } from 'antd';
import { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

interface Props {
  visible: boolean;
  docUrl: string;
  onClose: () => void;
}

export const FilePreviewModal: React.FC<Props> = ({
  visible,
  docUrl = 'https://www.ceasma-benin.org/fichiers/documents_rapports/5q6v27extraitduregistredecommercedevision2kpi.pdf',
  onClose,
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <Modal width={'50%'} open={visible} footer={null} centered onCancel={onClose}>
      <Flex justify="center">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          {loading ? (
            <p style={{ textAlign: 'center' }}>
              {"Merci de patienter l'affichage du document peut prendre quelques minutes"}
            </p>
          ) : null}
          <Viewer defaultScale={0.8} fileUrl={docUrl} onDocumentLoad={() => setLoading(false)} />
        </Worker>
      </Flex>
    </Modal>
  );
};
