/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpApi } from '@app/api/http.api';
import { PropertyModel } from '@app/model/PropertyModel';
import { AxiosResponse } from 'axios';

export const createPropertyApi = (payload: any): Promise<PropertyModel> =>
  httpApi.post<PropertyModel>('properties', payload).then(({ data }) => data);

export const updatePropertyApi = (payload: any): Promise<PropertyModel> =>
  httpApi.put<PropertyModel>(`properties`, payload).then(({ data }) => data);

export const getPropertiesApi = (): Promise<PropertyModel[]> =>
  httpApi.get<PropertyModel[]>('properties').then(({ data }) => data);

export const deletePropertyApi = (id: string): Promise<AxiosResponse<void>> => httpApi.delete<void>(`properties/${id}`);
