import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from './VoletSocial.styles';
import { FormTitle } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Col, Divider, Flex, Image, Row, Typography } from 'antd';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';

const VoletSocialDetailPage: React.FC = () => {
  const blogs = useAppSelector((state) => state.blog);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const data = blogs[params?.id || ''];

  const { Title } = Typography;

  const goBack = () => {
    navigate('/volet-social/list');
  };

  return (
    <>
      <PageTitle>{t('association_detail')}</PageTitle>
      <Flex gap="small">
        <BaseButton type={'default'} onClick={goBack} icon={<ArrowLeftOutlined />} />
        <FormTitle style={{ marginTop: 10 }}>{t('association_detail')}</FormTitle>
      </Flex>
      <br />
      <S.Card padding="1.25rem 1.25rem 0">
        <Row>
          <Col span={24}>
            <div style={{ justifyContent: 'center', display: 'flex', marginBottom: 40 }}>
              <Image src={data?.imageUrl} style={{ height: 400 }} />
            </div>
            <Divider />
            <Title level={4}>{data?.title}</Title>
            <Divider />
            <p dangerouslySetInnerHTML={{ __html: data?.description }} />
          </Col>
        </Row>
      </S.Card>
    </>
  );
};

export default VoletSocialDetailPage;
