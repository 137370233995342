import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from './Acheter.styles';
import { FormTitle } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Col, Divider, Flex, Row, Typography } from 'antd';
import { KeyValue } from '@app/components/common/KeyValue/KeyValue';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { AcheterModel } from '@app/model/ContactModel';

const AcheterDetailPage: React.FC = () => {
  const acheters = useAppSelector((state) => state.contact.acheter);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const data = acheters.filter((val: AcheterModel) => val.uuid === params?.id)?.shift();

  const { Title } = Typography;

  const goBack = () => {
    navigate('/acheter/list');
  };

  return (
    <>
      <PageTitle>{t('acheter_detail')}</PageTitle>
      <Flex gap="small" align="center">
        <BaseButton type={'default'} onClick={goBack} icon={<ArrowLeftOutlined />} />
        <FormTitle style={{ marginTop: 10 }}>{t('acheter_detail')}</FormTitle>
      </Flex>
      <br />
      <S.Card padding="1.25rem 1.25rem 0">
        <Row>
          <Col span={12}>
            <Title level={4}>Information Personnelle</Title>
            <Divider />
            <KeyValue title="Prénom" value={data?.surname} />
            <KeyValue title="Nom" value={data?.name} />
            <KeyValue title="Courriel" value={data?.email} />
            <KeyValue title="Téléphone" value={data?.phonenumber?.toString()} />
            <KeyValue title="Ville" value={data?.town} />
            <KeyValue title="Adresse" value={data?.address} />
            <KeyValue title="Code postal" value={data?.postalcode?.toString()} />
          </Col>
          <Col span={12}>
            <Title level={4}>Caractéristiques de la maison recherchée</Title>
            <Divider />
            <KeyValue title="Type de propriété" value={t(data?.typePropriete || '')} />
            <KeyValue title="Nombre de chambres" value={data?.numChambre?.toString()} />
            <KeyValue title="Nombre de salle de bains" value={data?.salleBain?.toString()} />
            <KeyValue title="Région" value={data?.region} />
            <KeyValue title="Budget maximal" value={`$${data?.budgetMax}`} />
            <KeyValue title="Dans combien de jours désirez-vous emménager?" value={t(data?.jourEmmenagez || '')} />
            <KeyValue title="Situation actuelle" value={t(data?.situationActuelle || '')} />
          </Col>
          <Col span={24}>
            <KeyValue title="Message" value={data?.message} />
          </Col>
        </Row>
      </S.Card>
    </>
  );
};

export default AcheterDetailPage;
