import { BaseProgress } from '@app/components/common/BaseProgress/BaseProgress';
import React from 'react';
import * as S from './StatisticsProgress.styles';

interface StatisticsProgressProps {
  color: string;
  value: number;
}

export const StatisticsProgress: React.FC<StatisticsProgressProps> = ({ color, value }) => {
  return (
    <BaseProgress
      type="circle"
      size={50}
      strokeColor={color}
      trailColor="transparent"
      percent={100}
      format={() => (
        <>
          <S.ValueText>{value}</S.ValueText>
        </>
      )}
    />
  );
};
