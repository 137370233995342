import { useDispatch } from 'react-redux';
import { Flex, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseButton } from './BaseButton/BaseButton';
import { doLogout } from '@app/store/slices/authSlice';

interface Props {
  visible: boolean;
}

export const SessionExpiredModal: React.FC<Props> = ({ visible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onConnect = () => {
    dispatch(doLogout());
  };

  return (
    <Modal open={visible} footer={null} centered closable={false}>
      <Flex justify="center">
        <div>
          <h1 style={{ textAlign: 'center' }}>{t('session_expired')}</h1>
          <p style={{ textAlign: 'center', marginBottom: 30 }}>{t('session_expired_desc')}</p>
          <BaseButton block type="primary" onClick={onConnect}>
            {t('connect')}
          </BaseButton>
        </div>
      </Flex>
    </Modal>
  );
};
