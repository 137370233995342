/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, Callback } from '../store';
import { AcheterModel, CalculatriceModel, ContactModel, DemandeEvalModel } from '@app/model/ContactModel';
import {
  deleteAcheterApi,
  deleteCalculatriceApi,
  deleteContactApi,
  deleteDemandeEvalApi,
  getAcheterApi,
  getCalculatriceApi,
  getContactApi,
  getDemandeEvalApi,
} from '@app/api/contact.api';
import { toggleSessionExpired } from './uiSlice';

interface InitContactsType {
  contacts: ContactModel[];
  calculatrice: CalculatriceModel[];
  acheter: AcheterModel[];
  demandEval: DemandeEvalModel[];
}

const initialState: InitContactsType = {
  contacts: [],
  calculatrice: [],
  acheter: [],
  demandEval: [],
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    listCalculatrice: (state, action) => {
      state.calculatrice = action.payload;
      return state;
    },
    listAcheter: (state, action) => {
      state.acheter = action.payload;
      return state;
    },
    listDemandEval: (state, action) => {
      state.demandEval = action.payload;
      return state;
    },
    listContact: (state, action) => {
      state.contacts = action.payload;
      return state;
    },
  },
});

const { listCalculatrice, listAcheter, listDemandEval, listContact } = contactsSlice.actions;

export const listAsyncCalculatrice = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getCalculatriceApi();
    dispatch(listCalculatrice(res));
  } catch (error: any) {
    if (error?.message?.includes('401')) {
      dispatch(toggleSessionExpired(true));
    }
  }
};

export const deleteAsyncCalculatrice =
  (id: string, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      deleteCalculatriceApi(id)
        .then(() => {
          dispatch(listAsyncCalculatrice());
          callback?.();
        })
        .catch((error) => {
          console.log('delete calculatrice error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const listAsyncAcheter = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getAcheterApi();
    dispatch(listAcheter(res));
  } catch (error: any) {
    if (error?.message?.includes('401')) {
      dispatch(toggleSessionExpired(true));
    }
  }
};

export const deleteAsyncAcheter =
  (id: string, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      deleteAcheterApi(id)
        .then(() => {
          dispatch(listAsyncAcheter());
          callback?.();
        })
        .catch((error) => {
          console.log('delete Acheter error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const listAsyncDemandEval = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getDemandeEvalApi();
    dispatch(listDemandEval(res));
  } catch (error: any) {
    if (error?.message?.includes('401')) {
      dispatch(toggleSessionExpired(true));
    }
  }
};

export const deleteAsyncDemandEval =
  (id: string, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      deleteDemandeEvalApi(id)
        .then(() => {
          dispatch(listAsyncDemandEval());
          callback?.();
        })
        .catch((error) => {
          console.log('delete DemandEval error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const listAsyncContact = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getContactApi();
    dispatch(listContact(res));
  } catch (error: any) {
    if (error?.message?.includes('401')) {
      dispatch(toggleSessionExpired(true));
    }
  }
};

export const deleteAsyncContact =
  (id: string, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      deleteContactApi(id)
        .then(() => {
          dispatch(listAsyncContact());
          callback?.();
        })
        .catch((error) => {
          console.log('delete Contact error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export default contactsSlice.reducer;
