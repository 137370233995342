/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice } from '@reduxjs/toolkit';
import { VoletSocialModel } from '@app/model/VoletSocialModel';
import {
  createVoletSocialApi,
  deleteVoletSocialApi,
  getVoletSocialsApi,
  updateVoletSocialApi,
} from '@app/api/voletsocial.api';
import { AppDispatch, Callback } from '../store';
import { getObjectKeys } from '@app/utils/utils';

const initialState: Record<string, VoletSocialModel> = {};

export const voletSocialSlice = createSlice({
  name: 'voletSocial',
  initialState,
  reducers: {
    addVoletSocial: (state, action) => {
      state[action.payload.uuid] = action.payload;
      return state;
    },
    listVoletSocial: (state, action) => {
      state = {};
      for (const obj of action.payload) {
        state[obj.uuid] = obj;
      }
      return state;
    },
    removeVoletSocial: (state, action) => {
      delete state[action.payload];
      return state;
    },
    updateVoletSocial: (state, action) => {
      state[action.payload.uuid] = {
        ...state[action.payload.uuid],
        ...action.payload,
      };
      return state;
    },
  },
});

const { addVoletSocial, updateVoletSocial, listVoletSocial, removeVoletSocial } = voletSocialSlice.actions;

export const createAsyncVoletSocial =
  (data: VoletSocialModel, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      const toSave = new FormData();
      const image = data.image;

      const keysData: Array<keyof VoletSocialModel> = getObjectKeys(data);

      keysData
        .filter((val: any) => val !== 'image')
        .forEach((key) => {
          toSave.append(key, '' + data[key]);
        });

      toSave.append('imageFile', image);
      createVoletSocialApi(toSave)
        .then((res) => {
          dispatch(addVoletSocial(res));
          callback?.();
        })
        .catch((error) => {
          console.log('create voletSocial error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const updateAsyncVoletSocial =
  (data: VoletSocialModel, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      const toSave = new FormData();
      const image = data.image;

      const keysData: Array<keyof VoletSocialModel> = getObjectKeys(data);

      keysData
        .filter((val: any) => !['image', 'images', 'createdAt', 'imageMediaId', 'imageUrl', 'imageFile'].includes(val))
        .forEach((key) => {
          toSave.append(key, '' + data[key]);
        });

      if (image && image !== 'null') {
        toSave.append('imageFile', image);
      } else {
        toSave.append('imageFile', new Blob());
      }

      if (data?.imageMediaId && data?.imageMediaId !== 'null') {
        toSave.append('imageMediaId', data.imageMediaId);
      }

      updateVoletSocialApi(toSave)
        .then((res) => {
          dispatch(updateVoletSocial(res));
          callback?.();
        })
        .catch((error) => {
          console.log('update voletSocial error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const listAsyncVoletSocials = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getVoletSocialsApi();
    dispatch(listVoletSocial(res));
  } catch (error) {
    console.log('list voletSocials error', error);
  }
};

export const deleteAsyncVoletSocial =
  (id: string, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      deleteVoletSocialApi(id)
        .then(() => {
          dispatch(removeVoletSocial(id));
          callback?.();
        })
        .catch((error) => {
          console.log('delete VoletSocial error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export default voletSocialSlice.reducer;
