/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpApi } from '@app/api/http.api';
import { ConseilModel } from '@app/model/ConseilModel';
import { AxiosResponse } from 'axios';

export const createConseilApi = (payload: any): Promise<ConseilModel> =>
  httpApi.post<ConseilModel>('conseils', payload).then(({ data }) => data);

export const updateConseilApi = (payload: any): Promise<ConseilModel> =>
  httpApi.put<ConseilModel>(`conseils`, payload).then(({ data }) => data);

export const getConseilsApi = (): Promise<ConseilModel[]> =>
  httpApi.get<ConseilModel[]>('conseils').then(({ data }: any) => data);

export const deleteConseilApi = (id: string): Promise<AxiosResponse<void>> => httpApi.delete<void>(`conseils/${id}`);
