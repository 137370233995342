export type Dimension = number | string;

export type xData = number[] | string[];

export type LanguageType = 'fr' | 'en';

export type ThemeType = 'light' | 'dark';

export type Severity = 'success' | 'error' | 'info' | 'warning';

export type TwoFactorAuthOption = 'email' | 'phone';

export type ActivityStatusType = 'sold' | 'booked' | 'added';

export enum CurrencyTypeEnum {
  USD = 'USD',
  ETH = 'ETH',
  BTC = 'BTC',
}
