/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpApi } from '@app/api/http.api';
import { BlogModel } from '@app/model/BlogModel';
import { AxiosResponse } from 'axios';

export const createBlogApi = (payload: any): Promise<BlogModel> =>
  httpApi.post<BlogModel>('blogs', payload).then(({ data }) => data);

export const updateBlogApi = (payload: any): Promise<BlogModel> =>
  httpApi.put<BlogModel>(`blogs`, payload).then(({ data }) => data);

export const getBlogsApi = (): Promise<BlogModel[]> => httpApi.get<BlogModel[]>('blogs').then(({ data }: any) => data);

export const deleteBlogApi = (id: string): Promise<AxiosResponse<void>> => httpApi.delete<void>(`blogs/${id}`);
