import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/store/slices/authSlice';
// import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import propertyReducer from '@app/store/slices/propertySlice';
import blogReducer from '@app/store/slices/blogSlice';
import contactReducer from '@app/store/slices/contactsSlice';
import voletSocialReducer from '@app/store/slices/voletSocialSlice';
import conseilReducer from '@app/store/slices/conseilSlice';
import uiReducer from '@app/store/slices/uiSlice';

export default {
  user: userReducer,
  auth: authReducer,
  // nightMode: nightModeReducer,
  theme: themeReducer,
  property: propertyReducer,
  blog: blogReducer,
  contact: contactReducer,
  voletSocial: voletSocialReducer,
  conseil: conseilReducer,
  ui: uiReducer,
};
