/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { References } from '@app/components/common/References/References';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { StatisticsCard } from '@app/components/common/statisticsCards/statisticsCard/StatisticsCard/StatisticsCard';
import { CompassFilled } from '@ant-design/icons';
import { useAppSelector } from '@app/hooks/reduxHooks';

const DashboardPage: React.FC = () => {
  const blogs = useAppSelector((state) => Object.values(state.blog));
  const properties = useAppSelector((state) => Object.values(state.property));
  const contacts = useAppSelector((state) => state.contact);
  const conseils = useAppSelector((state) => Object.values(state.conseil));
  const voletSocials = useAppSelector((state) => Object.values(state.voletSocial));

  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('dashboard')}</PageTitle>
      <BaseRow>
        <S.LeftSideCol xl={24} xxl={24}>
          <BaseRow gutter={[30, 30]}>
            <BaseCol span={24}>
              <BaseRow gutter={[30, 30]}>
                <StatisticsCard name={'Propriétés'} value={properties.length} Icon={() => <CompassFilled />} />
                <StatisticsCard
                  name={"Demandes d'achat"}
                  value={contacts.acheter.length}
                  Icon={() => <CompassFilled />}
                />
                <StatisticsCard
                  name={'Demandes de contact'}
                  value={contacts.contacts.length}
                  Icon={() => <CompassFilled />}
                />
                <StatisticsCard
                  name={'Calculatrice hypothécaire'}
                  value={contacts.calculatrice.length}
                  Icon={() => <CompassFilled />}
                />
                <StatisticsCard
                  name={"Demandes d'évaluation"}
                  value={contacts.demandEval.length}
                  Icon={() => <CompassFilled />}
                />
                <StatisticsCard name={"Nombres d'articles"} value={blogs.length} Icon={() => <CompassFilled />} />
                <StatisticsCard
                  name={'Conseil acheteur'}
                  value={conseils.filter((val: any) => val.type === 'ACHETER').length}
                  Icon={() => <CompassFilled />}
                />
                <StatisticsCard
                  name={'Conseil vendeur'}
                  value={conseils.filter((val: any) => val.type === 'VENDRE').length}
                  Icon={() => <CompassFilled />}
                />
                <StatisticsCard name={'Volet social'} value={voletSocials.length} Icon={() => <CompassFilled />} />
              </BaseRow>
            </BaseCol>
          </BaseRow>
          <References />
        </S.LeftSideCol>
      </BaseRow>
    </>
  );
};

export default DashboardPage;
