/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from './Contact.styles';
import { FormTitle } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Col, Divider, Flex, Row, Typography } from 'antd';
import { KeyValue } from '@app/components/common/KeyValue/KeyValue';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { ContactModel } from '@app/model/ContactModel';

const ContactDetailPage: React.FC = () => {
  const contacts = useAppSelector((state) => state.contact.contacts);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { Title } = Typography;

  const data = contacts.filter((val: ContactModel) => val.uuid === params?.id)?.[0];

  const goBack = () => {
    navigate('/contact/list');
  };

  return (
    <>
      <PageTitle>{t('contact_detail')}</PageTitle>
      <Flex gap="small" align="center">
        <BaseButton type={'default'} onClick={goBack} icon={<ArrowLeftOutlined />} />
        <FormTitle style={{ marginTop: 10 }}>{t('contact_detail')}</FormTitle>
      </Flex>
      <br />
      <S.Card padding="1.25rem 1.25rem 0">
        <Row>
          <Col span={12}>
            <Title level={4}>Information Personnelle</Title>
            <Divider />
            <KeyValue title="Prénom" value={data?.name} />
            <KeyValue title="Nom" value={data?.surname} />
            <KeyValue title="Courriel" value={data?.email} />
            <KeyValue title="Téléphone" value={data?.phonenumber?.toString()} />
            <KeyValue title="Ville" value={data?.town} />
            <KeyValue title="Adresse" value={data?.address} />
            <KeyValue title="Code postal" value={data?.postalcode?.toString()} />
          </Col>
          <Col span={12}>
            <Title level={4}>Message</Title>
            <Divider />

            <KeyValue title="Message" value={data?.message} />
          </Col>
        </Row>
      </S.Card>
    </>
  );
};

export default ContactDetailPage;
