import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useLocation } from 'react-router-dom';
import { DASHBOARD_PATH } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { References } from '@app/components/common/References/References';
import { useDispatch } from 'react-redux';
import { listAsyncProperties } from '@app/store/slices/propertySlice';
import {
  listAsyncAcheter,
  listAsyncCalculatrice,
  listAsyncContact,
  listAsyncDemandEval,
} from '@app/store/slices/contactsSlice';
import { listAsyncBlogs } from '@app/store/slices/blogSlice';
import { listAsyncConseils } from '@app/store/slices/conseilSlice';
import { listAsyncVoletSocials } from '@app/store/slices/voletSocialSlice';

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const dispatch = useDispatch();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setIsTwoColumnsLayout([DASHBOARD_PATH, '/'].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  useEffect(() => {
    dispatch(listAsyncProperties());
    dispatch(listAsyncAcheter());
    dispatch(listAsyncCalculatrice());
    dispatch(listAsyncContact());
    dispatch(listAsyncDemandEval());
    dispatch(listAsyncBlogs());
    dispatch(listAsyncConseils());
    dispatch(listAsyncVoletSocials());
  }, [dispatch]);

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
          {!isTwoColumnsLayout && <References />}
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
