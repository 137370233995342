import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from './Conseil.styles';
import { FormTitle } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Col, Divider, Flex, Row, Typography } from 'antd';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ArrowLeftOutlined, FilePdfFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { FilePreviewModal } from '@app/components/common/FilePreviewModal';
import { renameFileDownload } from '@app/utils/utils';

const ConseilDetailPage: React.FC = () => {
  const conseils = useAppSelector((state) => state.conseil);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [visible, setVisible] = useState(false);

  const data = conseils[params?.id || ''];

  const { Title } = Typography;

  const goBack = () => {
    navigate(data.type === 'ACHETER' ? '/conseil/acheteur' : '/conseil/vendeur');
  };

  const openFile = () => {
    setVisible(!visible);
  };

  return (
    <>
      <PageTitle>{t('conseil_detail')}</PageTitle>
      <Flex gap="small" align="center">
        <BaseButton type={'default'} onClick={goBack} icon={<ArrowLeftOutlined />} />
        <FormTitle style={{ marginTop: 10 }}>{t('conseil_detail')}</FormTitle>
      </Flex>
      <br />
      <S.Card padding="1.25rem 1.25rem 0">
        <Row>
          <Col span={24}>
            <Title level={4}>{data?.title}</Title>
            <Divider />
            <BaseButton
              type={'default'}
              onClick={openFile}
              style={{ padding: 70, marginBottom: 20 }}
              icon={<FilePdfFilled style={{ fontSize: 100 }} />}
            />
            <p dangerouslySetInnerHTML={{ __html: data?.description }} />
          </Col>
        </Row>
      </S.Card>
      {visible && (
        <FilePreviewModal
          visible={visible}
          docUrl={renameFileDownload(data?.imageUrl || '').replace('/ninatchetgna', '')}
          onClose={openFile}
        />
      )}
    </>
  );
};

export default ConseilDetailPage;
