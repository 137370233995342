/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice } from '@reduxjs/toolkit';
import { ConseilModel } from '@app/model/ConseilModel';
import { createConseilApi, deleteConseilApi, getConseilsApi, updateConseilApi } from '@app/api/conseil.api';
import { AppDispatch, Callback } from '../store';
import { getObjectKeys } from '@app/utils/utils';

const initialState: Record<string, ConseilModel> = {};

export const conseilSlice = createSlice({
  name: 'conseil',
  initialState,
  reducers: {
    addConseil: (state, action) => {
      state[action.payload.uuid] = action.payload;
      return state;
    },
    listConseil: (state, action) => {
      state = {};
      for (const obj of action.payload) {
        state[obj.uuid] = obj;
      }
      return state;
    },
    removeConseil: (state, action) => {
      delete state[action.payload];
      return state;
    },
    updateConseil: (state, action) => {
      state[action.payload.uuid] = {
        ...state[action.payload.uuid],
        ...action.payload,
      };
      return state;
    },
  },
});

const { addConseil, updateConseil, listConseil, removeConseil } = conseilSlice.actions;

export const createAsyncConseil =
  (data: ConseilModel, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      const toSave = new FormData();
      const image = data.image;

      const keysData: Array<keyof ConseilModel> = getObjectKeys(data);

      keysData
        .filter((val: any) => val !== 'image')
        .forEach((key) => {
          toSave.append(key, '' + data[key]);
        });

      if (image && image !== 'null') {
        toSave.append('imageFile', image);
      } else {
        toSave.append('imageFile', new Blob());
      }
      createConseilApi(toSave)
        .then((res) => {
          dispatch(addConseil(res));
          callback?.(data.type);
        })
        .catch((error) => {
          console.log('create conseil error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const updateAsyncConseil =
  (data: ConseilModel, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      const toSave = new FormData();
      const image = data.image;

      const keysData: Array<keyof ConseilModel> = getObjectKeys(data);

      keysData
        .filter((val: any) => !['image', 'images', 'createdAt', 'imageMediaId', 'imageUrl', 'imageFile'].includes(val))
        .forEach((key) => {
          toSave.append(key, '' + data[key]);
        });

      if (image && image !== 'null') {
        toSave.append('imageFile', image);
      } else {
        toSave.append('imageFile', new Blob());
      }

      if (data?.imageMediaId && data?.imageMediaId !== 'null') {
        toSave.append('imageMediaId', data.imageMediaId);
      }

      updateConseilApi(toSave)
        .then((res) => {
          dispatch(updateConseil(res));
          callback?.(data.type);
        })
        .catch((error) => {
          console.log('update conseil error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const listAsyncConseils = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getConseilsApi();
    dispatch(listConseil(res));
  } catch (error) {
    console.log('list conseils error', error);
  }
};

export const deleteAsyncConseil =
  (id: string, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      deleteConseilApi(id)
        .then(() => {
          dispatch(removeConseil(id));
          callback?.();
        })
        .catch((error) => {
          console.log('delete Conseil error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export default conseilSlice.reducer;
