/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import { default as AntIcon } from '@ant-design/icons';
import { Text } from '../StatisticsInfo/StatisticsInfo.styles';
import { DashboardCard } from '@app/components/common/DashboardCard/DashboardCard';

interface StatisticsProps {
  $color: any;
}

export const IconWrapper = styled.div`
  margin-top: 0.25rem;
`;

export const Icon = styled(AntIcon)`
  font-size: 1.5rem;
`;

export const StatisticCard = styled(DashboardCard)<StatisticsProps>`
  line-height: 1;
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
  ${Text} {
    color: ${(props) => `var(--${props.$color}-color)`};
  }
`;
