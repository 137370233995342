/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice } from '@reduxjs/toolkit';
import { PropertyModel } from '@app/model/PropertyModel';
import { createPropertyApi, deletePropertyApi, getPropertiesApi, updatePropertyApi } from '@app/api/property.api';
import { AppDispatch, Callback } from '../store';
import { getObjectKeys } from '@app/utils/utils';

const initialState: Record<string, PropertyModel> = {};

export const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    addProperty: (state, action) => {
      state[action.payload.uuid] = action.payload;
      return state;
    },
    listProperty: (state, action) => {
      state = {};
      for (const obj of action.payload) {
        state[obj.uuid] = obj;
      }
      return state;
    },
    removeProperty: (state, action) => {
      delete state[action.payload];
      return state;
    },
    updateProperty: (state, action) => {
      state[action.payload.uuid] = {
        ...state[action.payload.uuid],
        ...action.payload,
      };
      return state;
    },
  },
});

const { addProperty, updateProperty, listProperty, removeProperty } = propertySlice.actions;

export const createAsyncProperty =
  (data: PropertyModel, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      const toSave = new FormData();
      const images = [...data.images].map((val: any) => val.originFileObj);

      const keysData: Array<keyof PropertyModel> = getObjectKeys(data);

      keysData
        .filter((val: any) => !['images', 'imageIds'].includes(val))
        .forEach((key) => {
          toSave.append(key, '' + data[key]);
        });

      images.map((img: any) => {
        toSave.append('images', img);
      });
      createPropertyApi(toSave)
        .then((res) => {
          dispatch(addProperty(res));
          callback?.();
        })
        .catch((error) => {
          console.log('create project error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const updateAsyncProperty =
  (data: PropertyModel, callback: Callback, onError: Callback) => async (dispatch: AppDispatch, getState: any) => {
    try {
      const toSave = new FormData();
      const propertyImages = Object.values(getState().property)?.map((val: any) => val?.images)?.[0];
      const images = [...data.images].map((val: any) => val.originFileObj).filter((val: any) => val !== undefined);
      const OldImages = [...data.images].map((val: any) => val.id).filter((val: any) => val !== undefined);
      const imageIds = propertyImages.map((val: any) => val.id).filter((val: any) => !OldImages.includes(val));

      const keysData: Array<keyof PropertyModel> = getObjectKeys(data);
      // console.log('images here', imageIds, propertyImages, OldImages);
      // return;
      keysData
        .filter((val: any) => !['images', 'imageIds'].includes(val))
        .forEach((key) => {
          toSave.append(key, '' + data[key]);
        });

      images.map((img: any) => {
        toSave.append('images', img);
      });

      if (!images.length) {
        toSave.append('images', new Blob());
      }

      if (!imageIds?.length) {
        toSave.append('imageIds', '');
      }

      imageIds?.map((img: any) => {
        toSave.append('imageIds', img);
      });
      updatePropertyApi(toSave)
        .then((res) => {
          dispatch(updateProperty(res));
          callback?.();
        })
        .catch((error) => {
          console.log('update project error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export const listAsyncProperties = () => async (dispatch: AppDispatch) => {
  try {
    const res = await getPropertiesApi();
    dispatch(listProperty(res));
  } catch (error) {
    console.log('list properties error', error);
  }
};

export const deleteAsyncProperty =
  (id: string, callback: Callback, onError: Callback) => async (dispatch: AppDispatch) => {
    try {
      deletePropertyApi(id)
        .then(() => {
          dispatch(removeProperty(id));
          callback?.();
        })
        .catch((error) => {
          console.log('delete Property error', error);
          onError?.();
        });
    } catch (error) {
      onError?.();
    }
  };

export default propertySlice.reducer;
