import { Flex } from 'antd';
import React from 'react';

interface Props {
  title: string;
  value: string | undefined;
}

export const KeyValue: React.FC<Props> = ({ title, value }) => {
  return (
    <Flex wrap="wrap" gap="small">
      <h2 style={{ fontWeight: 300, color: '#6a6a6a' }}>{title}:</h2>
      <h2>{value}</h2>
    </Flex>
  );
};
