/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpApi } from '@app/api/http.api';
import { AcheterModel, CalculatriceModel, ContactModel, DemandeEvalModel } from '@app/model/ContactModel';
import { AxiosResponse } from 'axios';

export const getCalculatriceApi = (): Promise<CalculatriceModel[]> =>
  httpApi.get<CalculatriceModel[]>('calculatrices').then(({ data }: any) => data);

export const deleteCalculatriceApi = (id: string): Promise<AxiosResponse<void>> =>
  httpApi.delete<void>(`calculatrices/${id}`);

export const getAcheterApi = (): Promise<AcheterModel[]> =>
  httpApi.get<AcheterModel[]>('acheter').then(({ data }: any) => data);

export const deleteAcheterApi = (id: string): Promise<AxiosResponse<void>> => httpApi.delete<void>(`acheter/${id}`);

export const getDemandeEvalApi = (): Promise<DemandeEvalModel[]> =>
  httpApi.get<DemandeEvalModel[]>('demande-evaluation').then(({ data }: any) => data);

export const deleteDemandeEvalApi = (id: string): Promise<AxiosResponse<void>> =>
  httpApi.delete<void>(`demande-evaluation/${id}`);

export const getContactApi = (): Promise<ContactModel[]> =>
  httpApi.get<ContactModel[]>('contacts').then(({ data }: any) => data);

export const deleteContactApi = (id: string): Promise<AxiosResponse<void>> => httpApi.delete<void>(`contacts/${id}`);
