import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import DashboardPage from '@app/pages/DashboardPages/DashboardPage';
import AcheterDetailPage from '@app/pages/acheter/AcheterDetailPage';
import DemandeEvalDetailPage from '@app/pages/demandeEval/DemandeEvalDetailPage';
import ContactDetailPage from '@app/pages/contact/ContactDetailPage';
import BlogDetailPage from '@app/pages/blog/BlogDetailPage';
import ConseilDetailPage from '@app/pages/conseil/ConseilDetailPage';
import VoletSocialDetailPage from '@app/pages/voletSocial/VoletSocialDetailPage';

const PropertiesPage = React.lazy(() => import('@app/pages/property/PropertiesPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const NewPropertyPage = React.lazy(() => import('@app/pages/property/NewPropertyPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const AcheterPage = React.lazy(() => import('@app/pages/acheter/AcheterPage'));
const ContactPage = React.lazy(() => import('@app/pages/contact/ContactPage'));
const BlogPage = React.lazy(() => import('@app/pages/blog/BlogPage'));
const NewBlogPage = React.lazy(() => import('@app/pages/blog/NewBlogPage'));
const ConseilPage = React.lazy(() => import('@app/pages/conseil/ConseilPage'));
const NewConseilPage = React.lazy(() => import('@app/pages/conseil/NewConseilPage'));
const VoletSocialPage = React.lazy(() => import('@app/pages/voletSocial/VoletSocialPage'));
const NewVoletSocialPage = React.lazy(() => import('@app/pages/voletSocial/NewVoletSocialPage'));
const CalculatricePage = React.lazy(() => import('@app/pages/calculatrice/CalculatricePage'));
const DemandeEvalPage = React.lazy(() => import('@app/pages/demandeEval/DemandeEvalPage'));

const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/dashboard';

const Dashboard = withLoading(DashboardPage);
const NewPropertyForm = withLoading(NewPropertyPage);

const Properties = withLoading(PropertiesPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Acheter = withLoading(AcheterPage);
const AcheterDetail = withLoading(AcheterDetailPage);
const Contact = withLoading(ContactPage);
const ContactDetail = withLoading(ContactDetailPage);
const Blog = withLoading(BlogPage);
const NewBlog = withLoading(NewBlogPage);
const BlogDetail = withLoading(BlogDetailPage);
const Conseil = withLoading(ConseilPage);
const NewConseil = withLoading(NewConseilPage);
const ConseilDetail = withLoading(ConseilDetailPage);
const VoletSocial = withLoading(VoletSocialPage);
const NewVoletSocial = withLoading(NewVoletSocialPage);
const VoletSocialDetail = withLoading(VoletSocialDetailPage);
const Calculatrice = withLoading(CalculatricePage);
const DemandeEval = withLoading(DemandeEvalPage);
const DemandeEvalDetail = withLoading(DemandeEvalDetailPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path={DASHBOARD_PATH} element={<Dashboard />} />
          <Route path="properties">
            <Route path="list" element={<Properties />} />
            <Route path="form" element={<NewPropertyForm />} />
            <Route path="edit/:id" element={<NewPropertyForm />} />
            <Route path="detail/:id" element={<NewPropertyForm />} />
          </Route>
          <Route path="blog">
            <Route path="list" element={<Blog />} />
            <Route path="form" element={<NewBlog />} />
            <Route path="edit/:id" element={<NewBlog />} />
            <Route path="detail/:id" element={<BlogDetail />} />
          </Route>
          <Route path="conseil">
            <Route path="acheteur" element={<Conseil type="ACHETER" />} />
            <Route path="vendeur" element={<Conseil type="VENDRE" />} />
            <Route path="form" element={<NewConseil />} />
            <Route path="edit/:id" element={<NewConseil />} />
            <Route path="detail/:id" element={<ConseilDetail />} />
          </Route>
          <Route path="volet-social">
            <Route path="list" element={<VoletSocial />} />
            <Route path="form" element={<NewVoletSocial />} />
            <Route path="edit/:id" element={<NewVoletSocial />} />
            <Route path="detail/:id" element={<VoletSocialDetail />} />
          </Route>
          <Route path="acheter">
            <Route path="list" element={<Acheter />} />
            <Route path="detail/:id" element={<AcheterDetail />} />
          </Route>
          <Route path="contact">
            <Route path="list" element={<Contact />} />
            <Route path="detail/:id" element={<ContactDetail />} />
          </Route>
          <Route path="demande-evaluation">
            <Route path="list" element={<DemandeEval />} />
            <Route path="detail/:id" element={<DemandeEvalDetail />} />
          </Route>
          <Route path="server-error" element={<ServerError />} />
          <Route path="calculatrice-hypothecaire" element={<Calculatrice />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
