import React from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import frFR from 'antd/lib/locale/fr_FR';
// import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
// import { useLanguage } from './hooks/useLanguage';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { SessionExpiredModal } from './components/common/SessionExpiredModal';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';

const App: React.FC = () => {
  // const { language } = useLanguage();
  const { sessionExpired } = useSelector((state: RootState) => state.ui);

  useThemeWatcher();

  return (
    <>
      <meta name="theme-color" />
      <GlobalStyle />
      <HelmetProvider>
        <ConfigProvider locale={frFR}>
          <AppRouter />
          {sessionExpired && <SessionExpiredModal visible={sessionExpired} />}
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
