import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatisticsInfo } from '../StatisticsInfo/StatisticsInfo';
import { StatisticsProgress } from '../StatisticsProgress/StatisticsProgress';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './StatisticsCard.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

interface StatisticsCardProps {
  name: string;
  value: number;
  Icon: React.FC;
}

export const StatisticsCard: React.FC<StatisticsCardProps> = ({ name, value, Icon }) => {
  const { isTablet: isTabletOrHigher } = useResponsive();

  const { t } = useTranslation();

  return (
    <BaseCol xs={8}>
      <S.StatisticCard padding="0.5rem" $color={'#0060ba'}>
        <BaseRow align={'middle'} wrap={false} gutter={[isTabletOrHigher ? 10 : 5, 0]}>
          <BaseCol>
            <S.Icon component={Icon} />
          </BaseCol>

          <BaseCol flex={1}>
            <BaseRow justify="space-between" align="middle" wrap={false}>
              <BaseCol>
                <StatisticsInfo name={t(name)} value={value} />
              </BaseCol>

              <BaseCol>
                <StatisticsProgress color={'#0060ba'} value={value} />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </S.StatisticCard>
    </BaseCol>
  );
};
