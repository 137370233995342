import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sessionExpired: false,
};

const uiSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleSessionExpired: (state, action) => {
      state.sessionExpired = action.payload;
      return state;
    },
  },
});

export default uiSlice.reducer;

export const { toggleSessionExpired } = uiSlice.actions;
