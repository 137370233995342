import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from './DemandeEval.styles';
import { FormTitle } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { Col, Divider, Flex, Row, Typography } from 'antd';
import { KeyValue } from '@app/components/common/KeyValue/KeyValue';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { DemandeEvalModel } from '@app/model/ContactModel';

const DemandeEvalDetailPage: React.FC = () => {
  const demandeEvals = useAppSelector((state) => state.contact.demandEval);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const data = demandeEvals.filter((val: DemandeEvalModel) => val.uuid === params?.id)?.shift();

  const { Title } = Typography;

  const goBack = () => {
    navigate('/demande-evaluation/list');
  };

  return (
    <>
      <PageTitle>{t('demande_eval_detail')}</PageTitle>
      <Flex gap="small" align="center">
        <BaseButton type={'default'} onClick={goBack} icon={<ArrowLeftOutlined />} />
        <FormTitle style={{ marginTop: 10 }}>{t('demande_eval_detail')}</FormTitle>
      </Flex>
      <br />
      <S.Card padding="1.25rem 1.25rem 0">
        <Row>
          <Col span={12}>
            <Title level={4}>{t('info_personnelle')}</Title>
            <Divider />
            <KeyValue title="Prénom" value={data?.surname} />
            <KeyValue title="Nom" value={data?.name} />
            <KeyValue title="Courriel" value={data?.email} />
            <KeyValue title="Téléphone" value={data?.phonenumber?.toString()} />
            <KeyValue title="Ville" value={data?.town} />
            <KeyValue title="Adresse" value={data?.address} />
            <KeyValue title="Code postal" value={data?.postalcode?.toString()} />
            <KeyValue title="Message" value={data?.message} />
          </Col>
          <Col span={12}>
            <Title level={4}>{t('apropos_propriete')}</Title>
            <Divider />
            <KeyValue title="Type de propriété" value={t(data?.typePropriete || '')} />
            <KeyValue title="Année de construction" value={data?.anneeConstruction?.toString()} />
            <KeyValue title="Superficie habitable" value={data?.superficie} />
            <KeyValue title="Nombre de salles de bain" value={data?.nombreSalleBain?.toString()} />
            <KeyValue title="Nombre de chambre hors-sol" value={data?.numChambreHorsSol?.toString()} />
            <KeyValue title="Sous-sol" value={t(data?.sousol || '')} />
            <KeyValue title="Type de piscine" value={t(data?.typePiscine || '')} />
            <KeyValue title="Stationnement garage" value={t(data?.stationnementGarage || '')} />
            <KeyValue title="Foyer-poêle" value={t(data?.foyerPoele || '')} />
            <KeyValue title="Nombre de pièces" value={data?.nombrePiece?.toString()} />
            <KeyValue title="Nombre de salles d'eau" value={data?.nombreSalleEau?.toString()} />
            <KeyValue title="Nombre de chambre sous-sol" value={data?.nombreChambreSousol?.toString()} />
          </Col>
        </Row>
      </S.Card>
    </>
  );
};

export default DemandeEvalDetailPage;
