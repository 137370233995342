/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { httpApi } from '@app/api/http.api';
import { VoletSocialModel } from '@app/model/VoletSocialModel';
import { AxiosResponse } from 'axios';

export const createVoletSocialApi = (payload: any): Promise<VoletSocialModel> =>
  httpApi.post<VoletSocialModel>('volet-social-organisme', payload).then(({ data }) => data);

export const updateVoletSocialApi = (payload: any): Promise<VoletSocialModel> =>
  httpApi.put<VoletSocialModel>(`volet-social-organisme`, payload).then(({ data }) => data);

export const getVoletSocialsApi = (): Promise<VoletSocialModel[]> =>
  httpApi.get<VoletSocialModel[]>('volet-social-organisme').then(({ data }: any) => data);

export const deleteVoletSocialApi = (id: string): Promise<AxiosResponse<void>> =>
  httpApi.delete<void>(`volet-social-organisme/${id}`);
